import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeaderPage } from "../components/structure"
export default function Conditions() {
  return (
    <Layout>
      <SEO title="Term & Conditions" />
      <HeaderPage
        color={props => props.theme.color.complimentry}
        bgColor={props => props.theme.color.bgFirst}
        title="We are transperant with opur process"
        catchy="Our Work Policies"
      />
    </Layout>
  )
}
